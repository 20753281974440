import React, { useState, ComponentType, useRef, useEffect } from "react"
import { useScroll, useMotionValueEvent } from "framer-motion"

export function videoAnimation(Component): ComponentType {
    return (props) => {
        const [loaded, setLoaded] = useState(false)

        const [value, setValue] = useState(0)

        const divRef = useRef(null)

        const { scrollYProgress } = useScroll({
            target: divRef,
            offset: ["start 0.01", "end 0.9"],

            //* offset: ["0.5 end", "end end"],//*
        })

        useMotionValueEvent(scrollYProgress, "change", (currentProgress) => {
            setValue(currentProgress)

            onScroll(currentProgress)
        })

        const getCurrentTimeFromPercent = (duration, percent) => {
            let currentTime = duration * percent

            return currentTime
        }

        const onScroll = (percent) => {
            const video = divRef.current.querySelector("video")

            console.log(video.readyState)

            if (video.readyState >= 3) {
                const timeInSeconds = getCurrentTimeFromPercent(
                    video.duration,
                    percent
                )

                video.currentTime = timeInSeconds
            }
        }

        return <Component {...props} ref={divRef} />
    }
}
